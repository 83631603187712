.footer {
  @include no-select;
  @include font-size;

  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin: $gutter 0;

  @include mobile {
    flex-direction: column-reverse;
    text-align: center;
  }

  a {
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom-color: currentColor;
    }
  }

  &__credits {
    @include mobile {
      @include font-size-sm;
    }

    p {
      display: inline-block;
      margin: 0;
    }

    span {
      margin-left: var(--flex-item-margin);
      text-transform: lowercase;

      @include mobile {
        margin-left: 0;
        display: block;
      }
    }
  }


  &__socials {
    display: flex;

    @include mobile {
      margin-bottom: $gutter;
      justify-content: center;
    }
  }

  &__social + &__social {
    margin-left: var(--flex-item-margin);
  }
}
