.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $gutter;

  &[data-length='1'] {
    grid-template-columns: 1fr;
  }

  @include mobile {
    grid-template-columns: 1fr;
  }

  @include big-screen {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    display: block;
    height: min-content;
  }

  figure {}
}
