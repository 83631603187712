.project {
  padding-top: var(--logo-height);

  &__header {
    display: grid;
    grid-template-columns: cols(4) 1fr;
    grid-gap: $gutter;
    padding-bottom: $gutter * 2;

    @include mobile {
      display: block
    }
  }

  &__header &__title span {
    border-bottom: 1px solid;
    width: fit-content;
  }

  &__header &__abstract {
    margin-top: 1em;
  }

  &__resume {
    @include font-size;
  }

  &__metas {
    @include font-size-sm;
    padding-top: var(--baseline-sm-offset);

    column-width: cols(4);
    column-gap: $gutter;

    @include mobile {
      width: 100%;
      padding-left: 0;
      column-count: 1;
    }
  }

  &__meta {
    display: block;
    break-inside: avoid;
    margin-bottom: 0.75em;
  }

  &__meta::before {
    content: attr(data-label);
    display: block;
    width: fit-content;
    font-weight: bold;
  }
}
