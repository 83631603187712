.menu {
  @include no-select;
  @include font-size;

  position: sticky;
  top: 0;
  z-index: 2;

  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  max-width: 100%;

  // The logo will stretch to match this height
  height: var(--logo-height);
  padding: $gutter 0;
  background: white;

  label[for=toggleMenuMobile],
  &__logo {
    display: block;
    position: absolute;
    top: $gutter;
    height: calc(var(--logo-height) - #{$gutter * 2} - var(--baseline-sm-offset));
    right: calc(#{cols(4)} + #{$gutter});

    @include devices (mobile, tablet) {
      display: none;
    }

    svg {
      height: 100%;
      width: auto;
    }
  }

  label[for=toggleMenuMobile] {
    display: none;
    cursor: pointer;
    position: absolute;
    left: 50%;
    right: unset;
    bottom: $gutter;
    transform: translateX(-50%);

    @include devices (mobile, tablet) {
      display: block;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @include devices (mobile, tablet) {
      display: none;
    }
  }

  #toggleMenuMobile:checked ~ & &__content {
    position: fixed;
    top: var(--logo-height);
    bottom: 0;
    left: 0;
    right: 0;

    background: white;
    padding: var(--logo-height) var(--flex-item-margin);
    font-size: 2.8rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .menu__links:first-child  {
      margin-top: calc(var(--logo-height) * -1.5);
    }

    & > .menu__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1em;
    }

    .menu__link { margin: 0; }
  }

  &__links {
    display: flex;
  }

  &__socials {
    @include desktop {
      display: none;
    }
  }

  &__link#subtitle {
    @include desktop {
      & ~ * {
        display: none;
      }
    }

    @include devices (mobile, tablet) {
      display: none;
    }
  }

  &__link {
    position: relative;
    text-transform: lowercase;
    border-bottom: 1px solid transparent;

    &.is-active > a {
      font-weight: bold;
      border-bottom-color: currentColor;
    }

    &:hover > a {
      border-bottom-color: currentColor;
    }

    & + & {
      margin-left: var(--flex-item-margin);
    }
  }

  &__link &__links {
    display: none;
    position: absolute;
    bottom: -2em;
    right: 0;
    background: white;
  }

  &__link:not(.is-active) &__links {
    display: none;
  }
}

#toggleMenuMobile { display: none; }
