.team-member {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include devices (mobile, tablet) {
    grid-template-columns: 1fr;
  }

  @include desktop {
    header.no-desktop { display: none; }
  }

  &__picture {
    width: cols(3);

    @include tablet { width: cols(6); }
    @include mobile { width: 100%; }
  }

  &__content {
    @include font-size-sm;
    margin-top: calc(var(--baseline-sm-offset) * -1);

    @include devices (mobile, tablet) {
      header { display: none; }
    }

    @include tablet {
      column-width: cols(6);
      column-gap: $gutter;
    }
  }

  &__header {
    margin-bottom: $gutter;

    @include mobile {
      h1 { margin-bottom: 0; }
    }
  }
}
