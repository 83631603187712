html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  @include font-size;

  min-height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'signal', sans-serif;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  background: white;
  color: black;
}

::selection  {
  background: black;
  color: white;
}

img {
  @include no-select;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  width: 100%;
  height: auto;
}

a {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid transparent;

  p & {
    border-bottom-color: currentColor;
  }

  &:focus-visible {
    outline: 0;
    background: black;
    color: white;
    fill: currentColor;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: normal;
}

p {
  margin-bottom: 1em;
  max-width: 60ch;
}

main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 0 $gutter;

  & > section {
    flex: 1;
    animation: fadeIn 1000ms forwards;
  }
}
