.team {
  margin-top: var(--logo-height);
  margin-bottom: $gutter * 2;

  &__title {
    margin-bottom: $gutter;
    span {
      border-bottom: 1px solid;
      width: fit-content;
    }
  }

  .projects__items {
    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
