@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';

@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';
@import 'sass/devices';

$gutter: 2rem;
$debug-grid: false;

:root {
  --flex-item-margin: 3ch;
  --logo-height: 12rem;

  --baseline-sm-offset: 3px;

  --font-size: 1.8rem;
  --line-height: 1.3;

  --font-size-sm: 1.6rem;
  --line-height-sm: 1.3;

  @include devices (mobile, tablet) {
    --logo-height: 8rem;
    --font-size: 2rem;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@mixin font-size {
  font-size: var(--font-size);
  line-height: var(--line-height);
}

@mixin font-size-sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

@function cols ($width) {
  @return calc(#{$width} * (100vw - #{$gutter}) / 12 - #{$gutter});
}

@import 'sass/base';
@import 'sass/grid';

@import 'components/Default';
@import 'components/Footer';
@import 'components/Gallery';
@import 'components/Home';
@import 'components/Menu';
@import 'components/Photoswipe';
@import 'components/Project';
@import 'components/Projects';
@import 'components/Team';
@import 'components/TeamMember';

main[data-barba-namespace='home'] {
  max-height: 100vh;
  .footer {
    display: none;
  }
}

main[data-barba-namespace='contact'] .wrapper {
  display: flex;
  flex-direction: column;

  iframe {
    flex: 1;
    filter: grayscale(100%);
    width: 100%;
    height: 100%;
  }
}
