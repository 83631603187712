.home {
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    height: auto;
    top: $gutter;

    @include devices (mobile, tablet) {
      top: unset;
      height: 100%;
      object-fit: cover;
      bottom: $gutter;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: $gutter solid white;
  }
}
