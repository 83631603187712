.default {
  margin-top: var(--logo-height);

  display: grid;
  grid-template-columns: cols(4) 1fr;
  grid-gap: $gutter;
  margin-bottom: $gutter * 2;

  @include mobile {
    margin-top: $gutter;
    display: block
  }

  h1 {
    span {
      border-bottom: 1px solid;
      width: fit-content;
    }

    @include mobile {
      margin-bottom: 1em;
    }
  }

  &__content {
    @include font-size-sm;
    padding-top: var(--baseline-sm-offset);

    column-width: cols(4);
    column-gap: $gutter;

    @include mobile {
      width: 100%;
      padding-left: 0;
      column-count: 1;
    }

    &.no-col {
      width: 100%;
      padding-left: 0;
      column-count: 1;
    }

    p {
      break-inside: avoid;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      margin-bottom: 1em;
    }

    li a {
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom-color: currentColor;
      }
    }
  }
}
