.projects {
  @include no-select;

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $gutter;

    @include tv {
      grid-template-columns: repeat(4, 1fr);
    }

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    &[data-size='tall'] {
      grid-row: span 2;
    }

    figure {
      position: relative;
      height: 100%;
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    figcaption {
      @include easing(200ms, opacity);
      @include font-size-sm;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      background: rgba(black, 0.3);
      color: white;
      padding: $gutter;

      opacity: 0;

      @include devices (mobile, tablet) {
        opacity: 1;
        background: linear-gradient(rgba(black, 0.3), rgba(black, 0.01) 30%);

        &[data-position=bottom] {
          background: linear-gradient(rgba(black, 0.01) 40%, rgba(black, 0.3));
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }

    &:hover figcaption {
      opacity: 1;
    }

    &-title,
    &-context {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
