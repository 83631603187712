.pswp {
  @include font-size-sm;

  &__bg {
    background-color: black;
  }

  &__img {
    background: black;
    padding: $gutter;
  }

  &__counter {
    padding: ($gutter) $gutter;
    color: white;
  }

  &__caption {
    @include no-select;
    background-color: black !important;
    opacity: 0.9;
    &__center {
      text-align: center;
      color: white !important;
      padding: $gutter;
    }
  }

  &__button {
    &--arrow--left,
    &--arrow--right {
      margin: 0;
    }
  }

  &__top-bar {
    padding: $gutter;
    background: none !important;
    opacity: 1 !important;
    display: table !important;
  }
}
